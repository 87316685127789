import React from 'react'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import GreenLine from '../components/GreenLine'
import SEO from '../components/SEO'
import TextContainer from '../components/TextContainer'

const Flaschenpost = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle, headerImage } = data.contentfulPage

  return (
    <Layout>

      <SEO postNode={postNode} pagePath="contact" customTitle />

      <GreenLine backgroundColor={'rgba(0,0,0,0)'} />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        {body &&
        <PageBody body={body} />
        }
      </Container>

    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "flaschenpost" }) {
      id
      title
      subtitle
      body {
        json
      }
    }
  }
`

export default Flaschenpost
